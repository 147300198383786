<template>
    <div class="targetList">
        <div class="b-table-sticky-header m-b-15 zone-list">
            <table class="table b-table table-sm m-b-0">
                <thead class="areas-header">
                    <tr>
                        <th>{{ $t('monitoringLocation.zone') }}</th>
                        <th>{{ $t('monitoringLocation.targets') }}</th>
                        <th :aria-sort="sortExpires" @click="sortAction">{{ $t('monitoringLocation.zone_expires_at') }}</th>
                        <th>{{ $t('monitoringLocation.actions') }}</th>
                    </tr>
                </thead>
                <tbody v-if="zones.length">
                    <tr v-for="(zone, index) in zones" :key="zone.id">
                        <td class="checkbox-col border-checkbox-section zone-title">
                            <span class="border-checkbox-group border-checkbox-group-primary">
                                <input class="border-checkbox" type="checkbox" :disabled="selectedZones.length >= 5 && !selectedZones.includes(zone.id)" :id="`zone_${zone.id}`" :value="zone.id" v-model="selectedZones">
                                <label class="form-label border-checkbox-label" :for="`zone_${zone.id}`">
                                    <strong><span v-if="zone.title.length <= 20">{{zone.title}}</span>
                            <span v-else :class="{'hideText': zone.title.length > 20}" class="d-inline-block" :content="zone.title" v-tippy="{ placement : 'top',  arrow: true  }">{{zone.title}}</span></strong>
                                <i class="feather icon-file-text f-16 m-l-5" v-if="zone.note" :content="`<span class='f-w-700'>${$t('monitoringLocation.note')}</span>`+': '+zone.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                                <span v-if="selectedZones.includes(zone.id)" class="badge-selected-zone" :class="[colorZone(zone.id)]"></span>
                                </label>
                            </span>
                        </td>
                        <td>
                            <ul v-if="zone.targets.length > 0">
                                <li v-for="target in zone.targets">
                                    <strong>{{ target.alias }}</strong><br>{{ target.param }}: {{ target.value }}
                                </li>
                            </ul>
                            <span v-else>-</span>
                        </td>
                        <td v-html="zone.expires_at ? moment(zone.expires_at, 'X').format(`DD.MM.YYYY, HH:mm`) : ''">
                        </td>
                        <td class="actions-col">
                            <div class="d-flex">
                                <button class="action-btn-target" @click="stopTask(zone.id)" v-if="zone.status">
                                    <i class="feather icon-pause f-w-600 f-16 text-c-red" :content="$t('monitoringLocation.stop_task')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                                </button>
    
                                <button class="action-btn-target" @click="startTask(zone.id)" v-else>
                                    <i class="feather icon-play f-w-600 f-16 text-c-green" :content="$t('monitoringLocation.start_task')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                                </button>
    
                                <button class="action-btn-target" v-if="zone.status" v-b-modal="`edit-area-${zone.id}`">
                                    <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoringLocation.edit_area')" v-tippy="{ placement : 'top',  arrow: true }"></i>
                                </button>
                            </div>
                        </td>
                        <b-modal :id="`edit-area-${zone.id}`" size="xl" centered hide-footer :title="$t('monitoringLocation.edit_area')">
                            <editArea :area="zone" @success="$emit('update')" @closeAreaModal="$bvModal.hide(`edit-area-${zone.id}`)" />
                        </b-modal>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="4">{{ $t('monitoringLocation.emptyList') }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import editArea from './editArea.vue'
    export default {
        name: 'zoneList',
        setup() {
            return {
                moment
            }
        },
        props: {
            value: {
                type: Array,
                default: () => []
            },
            zones: {
                type: Array,
                default: () => []
            }
        },
        components: {
            editArea
        },
        data() {
            return {
                selectedZones: this.value,
                sortExpires: 'none'
            }
        },
        watch: {
            value() {
                this.selectedZones = this.value
            },
            selectedZones() {
                this.$emit('input', this.selectedZones)
            }
        },
        methods: {
            stopTask(id) {
                const payload = {
                    id,
                    payload: {
                        status: false
                    }
                }
                this.$store.dispatch('locationMonitoring/updateArea', payload).then(() => {
                    this.$emit('update');
                })
            },
            startTask(id) {
                const payload = {
                    id,
                    payload: {
                        status: true,
                        alert_status: true
                    }
                }
                this.$store.dispatch('locationMonitoring/updateArea', payload).then(() => {
                    this.$emit('update');
                })
            },
            closeAreaModal() {
                this.createAreaModal = false
            },
            colorZone(id) {
                const index = this.selectedZones.indexOf(id)
                return `color-${index}`
            },
            sortDown() {
                this.zones.sort((a, b) => {
                    return new Date(b.expires_at) - new Date(a.expires_at)
                })
                this.sortExpires = 'descending';
            },
            sortUp() {
                this.zones.sort((a, b) => {
                    return new Date(a.expires_at) - new Date(b.expires_at)
                })
                this.sortExpires = 'ascending';
            },
            sortAction() {
                if (['ascending', 'none'].includes(this.sortExpires)) {
                    this.sortDown()
                } else {
                    this.sortUp()
                }
            },
        }
    }
</script>
<style lang="scss">
    .areas-header th{
        background-color: #fff;
    }
    .action-btn-target {
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 1300px) {
            padding: 0 2px;
        }
    }
    .checkbox-col {
        width: 40px;
    }
    .actions-col {
        width: 40px;
    }

    .color-0 {
        background-color: rgba(0, 123, 255, 0.5);
    }

    .color-1 {
        background-color: rgba(40, 167, 69, 0.5);
    }

    .color-2 {
        background-color: rgba(23, 162, 184, 0.5);
    }

    .color-3 {
        background-color: rgba(220, 53, 69, 0.5);
    }

    .color-4 {
        background-color: rgba(55, 71, 79, 0.5);
    }

    .badge-selected-zone {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 5px;
    }
    .header-btn {
        background: none;
        border: none;
        cursor: pointer;
    }
    
    .table.table-sm th[aria-sort] {
        padding-right: 10px !important;
    }
    @media (max-width: 1300px) {
        .zone-list .hideText {
            width: 65px;
        }
    }
    .zone-title {
        label {
            white-space: nowrap;
            margin-right: 0 !important;
        }
    }
</style>