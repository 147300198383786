<template>
  <div style="display: flex;justify-content: center;">
<!--    <span v-if="typeof loading !== 'undefined' && loading" style="display: flex;align-items: center">-->
<!--        <b-spinner small></b-spinner>-->
<!--    </span>-->

    <button class="action-btn-target"  v-b-modal="'delete_modal'+target.id"  style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-trash-2 f-w-600 f-16 text-c-red m-r-15" :content="$t('monitoring.delete_target')" v-tippy="{ placement : 'top',  arrow: true }"></i>
    </button>

    <button class="action-btn-target" v-b-modal="'update-modal-action'+target.id"  @click="showUpdateModal"  style="display: flex;align-items: center;cursor: pointer">
      <i class="feather icon-edit f-w-600 f-16 editIconColor" :content="$t('monitoring.edit_target')" v-tippy="{ placement : 'top',  arrow: true }"></i>
    </button>

    <b-modal class="deleteTarget" :id="'delete_modal'+target.id" ref="delete-modal" centered hide-footer :title=" $t('monitoring.delete_target_modal')">
      <template #modal-title>
        <span v-html="$t('monitoring.delete_target_modal', { value: target.value })"></span>
      </template>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="unsubscribe()">{{$t('modal.actionDelete') }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="hideDeleteModal()">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>


    <b-modal  :id="'update-modal-action'+target.id" ref="update-modal-action" centered hide-footer :title="this.$t('monitoring.edit_target')">
      <form @submit.prevent="updateTarget">
        <div class="dialog-content">
          <div class="form-group">
            <input v-model="alias" :placeholder="this.$t('monitoringLocation.title')" class="form-control">
          </div>
          <div class="form-group">
            <b-form-textarea :placeholder="this.$t('monitoring.note')"  v-model="note" style="min-height: 100px"></b-form-textarea>
          </div>
        </div>
        <div class="d-grid">
          <b-button type="submit" variant="primary" class="btn m-b-10"
                    :disabled="$v.$invalid || !somethingChange">{{ $t('user.save') }}
            <span v-if="isLoading">
              <b-spinner small></b-spinner>
            </span>
          </b-button>
          <b-button type="button" variant="secondary"
                    @click="hideUpdateModal" class="btn">{{ $t('user.cancel') }}
          </b-button>
        </div>
      </form>
    </b-modal>

  </div>
</template>

<script>
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: "locationTargetAction",
  props: ['target'],
  data() {
    return {
      loading: false,
      alias: '',
      note: '',
      isLoading: false
    }
  },
  computed: {
    somethingChange(){
      return this.alias !== this.target.alias || this.note !== this.target.note;
    }
  },
  validations() {
    let validation = {
      alias: {
        required,
        maxLength: maxLength(255)
      },
      note: {
        maxLength: maxLength(255)
      },
    }
    return validation
  },
  methods: {

    hideDeleteModal() {
      this.$refs['delete-modal'].hide();
    },
    showUpdateModal(){
      this.setTargetVals();
    },
    hideUpdateModal(){
      this.$refs['update-modal-action'].hide();
    },
    setTargetVals(){
      this.alias = this.target.alias
      if (this.target.note){
        this.note = ''
        this.note = JSON.parse(JSON.stringify(this.target.note))
      }
    },

    updateTarget(){
      this.$refs['delete-modal'].hide();
      this.loading = true
      let payload = {
        id: this.target.id,
        note: this.note,
        alias: this.alias
      }

      this.$store.dispatch('locationMonitoring/updateSubscription', payload).then(()=>{
        this.loading = false
        this.$emit('getTargets')
      }).catch(()=>{
        this.$emit('getTargets')
        this.loading = false
      })
    },
    unsubscribe() {
      this.$refs['delete-modal'].hide();
      this.loading = true
      this.$store.dispatch('locationMonitoring/unsubscribe', this.target.id).then(()=>{
        this.loading = false
        this.$emit('getTargets')
      }).catch(()=>{
        this.$emit('getTargets')
        this.loading = false
      })
    },
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>