<template>
  <div class="routerTaskCreate">
    <div v-if="isLoading" class="skeletonContainer">
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
    </div>
    <div v-if="!isLoading" class="createContent">
      <div class="scroll-container targetList" style="height: auto; max-height: 305px">
        <b-table
            v-if="targets.length"
            :items="filteredTargets"
            :fields="fields"
            :busy="isLoading"
            small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(alias)="row">
            <div class="border-checkbox-section">
              <div class="border-checkbox-group border-checkbox-group-primary" style="display:flex;">
                <input class="border-checkbox"
                       type="checkbox"
                       :id="`taskCreate`+row.value"
                       :disabled="!selectedTargets.includes(row.item.id) && selectedTargets.length > 0 || row.item.active"
                       :value="row.item.id"
                       v-model="selectedTargets">
                <label class="border-checkbox-label d-flex" :for="`taskCreate`+row.value">
                  <span v-if="row.value.length <= 25">{{row.value}}</span>
                  <span v-else :class="{'hideText': row.value.length > 25}" :content="row.value" v-tippy="{ placement : 'top',  arrow: true  }">{{row.value}}</span>
                  <i class="feather icon-file-text f-16 m-l-10" v-if="row.item.note" :content="`<span class='f-w-700'>${$t('monitoring.note')}</span>`+': '+row.item.note" style="color:#bebebe;" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                </label>
              </div>
            </div>
          </template>

          <template #cell(1)="row">
            <div style="display: flex">
              <div style="display: flex;justify-content: flex-start; align-items: center">
                <i class="feather icon-eye f-16" v-if="row.item.active"  :content="$t('monitoringLocation.status_tooltip_active')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                <i class="feather icon-eye-off f-16" v-else :content="$t('monitoringLocation.status_tooltip_disabled')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </div>
              <div style="display: flex;justify-content: flex-start; align-items: center" class="m-l-15 iconContainer">
                <i class="feather icon-info f-16" v-if="row.item.target.locations.length"  :content="$t('monitoringLocation.info_tooltip_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
                <i class="feather icon-info crossed  f-16" v-else :content="$t('monitoringLocation.info_tooltip_not_exist')" v-tippy="{ placement : 'top',  arrow: true, allowHTML: true }"></i>
              </div>
            </div>
          </template>



          <template #cell(2)="row">
            <task-create-period-select
              :item="row.item"
              :disabled="!selectedTargets.includes(row.item.id)"
              @setPeriod="setPeriod"
            />
          </template>
          <template #cell(3)="row">
            <task-create-interval-select
              :item="row.item"
              :disabled="!selectedTargets.includes(row.item.id)"
              @setInterval="setInterval" />
          </template>


        </b-table>
      </div>
    </div>
    <p>{{ 
      $t('monitoringLocation.estimated_price') }}: {{ calculatedPrice }} {{ $t('servicesSelect.c') }}<br><span 
        v-if="calculatedPrice > user.account.balance && !user.account.unlimited" class="text-warning">{{ $t('monitoringLocation.over_balance') }}</span></p>
  </div>


</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import TargetActions from "@/components/monitoring/targetActions";
import TaskCreatePeriodSelect from "@/components/monitoringLocation/taskCreatePeriodSelect";
import TaskCreateIntervalSelect from "@/components/monitoringLocation/taskCreateIntervalSelect";
import _ from "underscore";

export default {
  name: "routesTaskCreate",
  components: {TaskCreatePeriodSelect, TargetActions, TaskCreateIntervalSelect},
  setup() {
    return {
      moment
    }
  },
  data(){
    return {
      isEdit: true,
      anyloading: false,
      isLoading: false,
      selectedTargets: [],
      period: undefined,
      interval: 1,
      periodList: [3,7,10,14,21,30]
    }
  },
  methods: {
    setPeriod(val){
      this.period = val
    },
    setInterval(val){
      this.interval = val
    },
    anyLoading(val) {
      this.anyloading = val;
    }
  },
  watch: {
    selectedTargets(){
      this.$emit('setSelectedTarget', this.selectedTargets)
    },
    period(){
      this.$emit('setPeriod', this.period)
    },
    interval(){
      this.$emit('setInterval', this.interval)
    }
  },
  computed: {
    ...mapGetters('monitoring', ['targets']),
    ...mapGetters('locationMonitoring', ['prices']),
    ...mapGetters("auth", ["user"]),
    servicesPrices(){
      const serviceObj = {};
      this.prices.services_monitoring_rates.forEach((item) => {
        serviceObj[item.source] = item.price
      });
      return serviceObj;
    },
    defaultPrice(){
      const locationPrice = this.prices.monitoring_rates.find((item) => item.type === 'location');
      return !_.isUndefined(locationPrice) ? locationPrice.price : 0;
    },
    selectedServicesPrices(){
      let price = 0;
      this.selectedServices.forEach((item) => {
        const servicePrice = !_.isUndefined(this.servicesPrices[item]) ? this.servicesPrices[item] : this.defaultPrice;
        price += servicePrice;
      })
      return price;
    },
    calculatedPrice(){
      const period = this.period ? this.period : 30;
      return this.selectedServicesPrices * (1440/this.interval) * period;
    },
    filteredTargets() {
      if (this.targets.length){
        return this.targets.filter((target) => target.type === 'location' && !target.active)
      }
    },
    selectedServices() {
      if(this.selectedTargets.length === 0){
        return []
      }
      return _.flatten(this.selectedTargets
        .map((id) => this.targets.find((target) => target.id === id))
        .map((target) => target.target.locations))
        .map(item => item.source)
    },
    fields(){
      return  [
        {
          key: 'alias',
          label:  this.$t('monitoringLocation.target'),
          sortable: false
        },
        {
          key: '1',
          label:  this.$t('monitoringLocation.active_status'),
          sortable: false
        },
        {
          key: '2',
          label:  this.$t('monitoringLocation.observation_period'),
          sortable: false
        },
        {
          key: '3',
          label:  this.$t('monitoringLocation.observation_interval'),
          sortable: false
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.routerTaskCreate{
  .createContent{
    .scroll-container {
      max-height: 340px ;
      overflow-y: auto;
      padding-bottom: 16px;
    }
  }

  .iconContainer {
    position: relative;
    .crossed:after {
      content: "";
      position: absolute;
      left: 7px;
      top: -3px;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 22px;
      background-color: rgb(33, 37, 41);
      background-size: cover;
      transform: rotate(135deg);
    }
  }


  .skeletonContainer {
    padding: 8px;
  }

  .targetListHeader{
    display: flex;
    justify-content: space-between;
    padding: 8px 14px 0 14px;
    .titleContainer{
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 991px) {
    .scroll-container {
      overflow-y: scroll;
      padding-bottom: 30px;
    }
  }
  .table.table-sm td{
    padding: 6px !important;
  }
  .targetList{
    padding: 8px;
  }
}


</style>