<template>

    

</template>

<script>
export default {
    name: 'VisitedPlacesEvent',
    props: ['place', 'value'],
    data() {
        return {
            selectedPlaces: this.value
        }
    },
    methods: {
        emitChecked() {
            this.$emit('input', this.selectedPlaces);
        },
        
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
