<template>
    <div class="card">
        <div class="card-body">
            <date-range-picker
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
                    v-model="date"
                    >
                
            </date-range-picker>
        </div>
      </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    name: "zoneFilters",
    data() {
        return {
            date: {
                startDate: null,
                endDate: null
            }
        }
    },
    components: {
        DateRangePicker
    },
    setup() {
        
    },
    methods: {
        disabledBeforeToday(date) {
            return date < new Date();
        }
    }
}
</script>