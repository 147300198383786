<template>
    <td class="text-center maxWidth70 h4">
        <i class="event-detail feather icon-external-link" @click="getDetails(place)"></i>
        <b-modal ref="eventDetailModal" :title="$t('monitoringLocation.eventDetailModalTitle')" hide-footer>
            <h5>{{ target }}</h5>
            <p>{{ place.place.address }}</p>
            <table class="table" v-if="!isUndefined(placesDetails[place.id])">
                <thead>
                    <tr>
                        <th>{{ $t('monitoringLocation.start') }}</th>
                        <th>{{ $t('monitoringLocation.end') }}</th>
                        <th>{{ $t('monitoringLocation.duration') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="detail in placesDetails[place.id].data">
                        <td>{{ moment(detail.period_from, 'X').format('DD.MM.YYYY HH:mm') }}</td>
                        <td>{{ moment(detail.period_to, 'X').format('DD.MM.YYYY HH:mm') }}</td>
                        <td>{{ secToTime(Math.abs(moment(detail.period_from, 'X').diff(moment(detail.period_to, 'X'), 'seconds'))) }}</td>
                    </tr>
                </tbody>
            </table>
            <div v-if="loading">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{ $t('monitoringLocation.start') }}</th>
                            <th>{{ $t('monitoringLocation.end') }}</th>
                            <th>{{ $t('monitoringLocation.duration') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><b-skeleton width="85%"></b-skeleton></td>
                            <td><b-skeleton width="86%"></b-skeleton></td>
                            <td><b-skeleton width="80%"></b-skeleton></td>
                        </tr>
                        <tr>
                            <td><b-skeleton width="81%"></b-skeleton></td>
                            <td><b-skeleton width="62%"></b-skeleton></td>
                            <td><b-skeleton width="75%"></b-skeleton></td>
                        </tr>
                        <tr>
                            <td><b-skeleton width="65%"></b-skeleton></td>
                            <td><b-skeleton width="76%"></b-skeleton></td>
                            <td><b-skeleton width="70%"></b-skeleton></td>
                        </tr>
                        <tr>
                            <td><b-skeleton width="77%"></b-skeleton></td>
                            <td><b-skeleton width="85%"></b-skeleton></td>
                            <td><b-skeleton width="60%"></b-skeleton></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </td>
</template>

<script>
import { mapGetters } from 'vuex';
import { isUndefined } from 'underscore';
import moment from 'moment';
export default {
    name: 'VisitedPlacesEvent',
    props: ['place', 'range', 'target'],
    data() {
        return {
            loading: false
        }
    },
    setup() {
        return {
            isUndefined,
            moment
        }
    },
    methods: {
        getDetails(place) {
            this.$refs.eventDetailModal.show();
            if(isUndefined(this.placesDetails[place.id])) {
                const payload = {id: place.id, payload: this.range}; 
                this.loading = true;
                this.$store.dispatch('locationMonitoring/getPlacesDetails', payload)
                    .then(() => {
                        this.loading = false;
                        this.$forceUpdate();
                    });
            }           
        },
        secToTime(sec_num) {
            let hours   = Math.floor(sec_num / 3600);
            let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            let seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours   < 10) {hours   = "0"+hours;}
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            const time = hours+':'+minutes+':'+seconds;
            return time === '00:00:00' ? '-' : time;
        },
        toHHMMSS(mins) {
            let sec_num = parseInt(mins, 10)*60;
            return this.secToTime(sec_num);
        },
    },
    computed: {
        ...mapGetters('locationMonitoring', ['placesDetails'])
    }
};
</script>
<style scoped>
    .maxWidth70 {
        max-width: 70px;
        width: 70px;
    }
</style>